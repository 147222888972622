import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ValidationMessage} from '../plugin-booking/model/validation-message';
import {Resource} from '../model/resource';
import {Reservation} from '../plugin-booking/model/reservation';
import {ReservationType} from '../plugin-booking/model/reservation-type';
import {map} from 'rxjs/operators';
import {Conflict} from '../plugin-booking/model/conflict';
import {Observable} from 'rxjs';

@Injectable()
export class BookingService {
  _showArchivedReservations: boolean = false;

  constructor(private http: HttpClient) {}

  getTodayEcawin() {
    return this.http.get('/api/booking/reservations/today/ecawin');
  }

  getConflicts(reservation: Reservation) {
    return this.http
      .post('/api/booking/reservations/conflicts', reservation)
      .pipe(map((array: any[]) => array.map(value => new Conflict(value))));
  }

  getResources(): Observable<Resource[]> {
    return this.http
      .get('/api/booking/resources')
      .pipe(
        map((list: unknown[]) =>
          list.map((resource: unknown) => new Resource(resource))
        )
      );
  }

  getEcawinEvents() {
    return this.http.get('/api/booking/reservations/calendar/ecawin');
  }

  getUnlockedResources(): Observable<Resource[]> {
    return this.http
      .get('/api/booking/resources/unlocked')
      .pipe(
        map((list: unknown[]) =>
          list.map((resource: unknown) => new Resource(resource))
        )
      );
  }

  createReservation(reservation: Reservation) {
    return this.http.post('/api/booking/reservations', reservation);
  }

  createReservationAndValidate(reservation: Reservation) {
    return this.http.post(
      '/api/booking/reservations/and-validate',
      reservation
    );
  }

  find(reservationId: number) {
    return this.http.get('/api/booking/reservations/' + reservationId);
  }

  editReservation(reservation: Reservation) {
    return this.http.put(
      '/api/booking/reservations/' + reservation.id,
      reservation
    );
  }

  validate(reservation: Reservation) {
    return this.http.patch(
      '/api/booking/reservations/validate/' + reservation.id,
      null
    );
  }

  remove(reservation: Reservation) {
    return this.http.put('/api/booking/reservations/refuse/' + reservation.id, null);
  }

  editResource(editResource: Resource) {
    return this.http.put(
      '/api/booking/resources/' + editResource.id,
      editResource
    );
  }

  getSites() {
    return this.http.get('/api/booking/resources/sites');
  }

  newResource(editResource: Resource) {
    return this.http.post('/api/booking/resources', editResource);
  }

  lockResource(resource: Resource) {
    return this.http.patch(
      '/api/booking/resources/' + resource.id + '/lock',
      {}
    );
  }

  unlockResource(resource: Resource) {
    return this.http.patch(
      '/api/booking/resources/' + resource.id + '/unlock',
      {}
    );
  }

  findAllUsers() {
    return this.http.get('/api/users');
  }

  sendMessage(reservation: Reservation, message: ValidationMessage) {
    return this.http.post(
      '/api/booking/reservations/' + reservation.id + '/message',
      message
    );
  }

  getEcawinEventDetails(ecawinId: string) {
    return this.http.get('/api/booking/reservations/ecawin/' + ecawinId);
  }

  getReservationsPage(
    onlyLoggedUser: boolean,
    number: number,
    itemsBypage: any,
    sortColumn: string,
    sortDirection: string
  ) {
    let archives = this._showArchivedReservations
      ? 'archives=true'
      : 'archives=false';
    let user = onlyLoggedUser ? '&user=true' : '&user=false';
    return this.http.get(
      '/api/booking/reservations/journal?' +
        archives +
        user +
        '&sort=' +
        sortColumn +
        ',' +
        sortDirection +
        '&page=' +
        number +
        '&size=' +
        itemsBypage
    );
  }

  findAllReservationTypes() {
    return this.http.get('/api/booking/types');
  }

  createReservationType(reservationType: ReservationType) {
    return this.http.post('/api/booking/types', reservationType);
  }

  deleteReservationType(reservationType: ReservationType) {
    return this.http.delete('/api/booking/types/' + reservationType.id);
  }

  editReservationType(reservationType: ReservationType) {
    return this.http.put(
      '/api/booking/types/' + reservationType.id,
      reservationType
    );
  }

  deleteBooking(reservation: Reservation) {
    return this.http.delete(
      '/api/booking/reservations/' + reservation.id
    );
  }
}
