import { Reservation } from './reservation';
import { Resource } from '../../model/resource';

export class Conflict {
  reservation: Reservation;
  resource: Resource;
  message: string;

  constructor(json?: any) {
    if (json) {
      this.reservation = new Reservation(json.reservation);
      this.resource = new Resource(json.resource);
      this.message = json.message;
    }
  }
}
